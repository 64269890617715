import {Button} from "../UI/button";
import {Backdrop} from "../UI/Backdrop";

import {useEffect, useState} from "react";

export const InfoTurismo = () => {
    // const lang = localStorage.getItem('i18nextLng');

    const [totem, setTotem] = useState(false)
    const [title, setTitle] = useState(null);
    const [image, setImage] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_1_hp_f_2[0].value);
                setImage(data[0].field_immagine_1_hp_f_2[0].url);
            })
            .catch(error => console.error(error));

    }, []);

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    return (
        <section id='infoTurismo'>
            <img src={image} alt='Info Turismo' className={`w-full ${totem === true && 'h-520'} ${totem === false && 'h-40'} object-cover rounded-20`}/>
            <h1 className='mb-5 text-left text-205rem font-medium text-white absolute top-0 px-6 py-4 pb-20 z-9999 break-words'>{title}</h1>
            <Button light link='/info-turismo'/>
            <Backdrop/>
        </section>
    );
}
