import {useExternalScripts} from "../components/hooks/useExternalScripts";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import {BackButton} from "../components/UI/BackButton";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {Header} from "../components/Header";

export const PuntiDiInteressePage = () => {

    let lang = localStorage.getItem('i18nextLng');

    useExternalScripts(`https://widget.lts.it/poifinder/?widgetid=totem-bbs-algund-poi&language=${lang}&maincolor=96A42C`);

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    return (
        <>
            <Header></Header>
        <section id='punti-di-interesse' className='bg-white main'>
            <>
                {totem === true &&
                    <>
                        <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 pb-10 pt-10 mb-10 bg-green text-left text-6xl font-medium text-white'>{t('puntidiinteresse')}</h1>
                        <div className='md:pl-24 md:pr-24 pl-6 pr-6 overflow-auto h-screen-modify-widget'>

                            <div id='lts-apwidget-container' className='w-full pb-10'><LoadingSpinner/></div>
                        </div>
                        <BackButton light url='/info-turismo' />
                    </>
                }
                {totem === false &&
                    <>
                        <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 lg:pb-10 lg:pt-10 pt-8 pb-8 lg:mb-10 bg-green text-left lg:text-6xl text-4xl font-medium text-white'>{t('puntidiinteresse')}</h1>
                        <div className='md:pl-24 md:pr-24 pl-6 pr-6 overflow-auto h-screen-modify-2'>

                            <div id='lts-apwidget-container' className='w-full pb-10'><LoadingSpinner/></div>
                        </div>
                        <div className='h-11'>
                        <BackButton light url='/info-turismo' />
                        </div>
                    </>
                }
            </>
        </section>
        </>
    );
}
