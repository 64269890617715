import {Button} from "../UI/button";
import {Backdrop} from "../UI/Backdrop";
import {useEffect, useState} from "react";

export const Eventi = () => {
    let lang = localStorage.getItem('i18nextLng');


    const [totem, setTotem] = useState(false)

    const [title, setTitle] = useState(null);
    const [image, setImage] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_2_hp_f_2[0].value);
                setImage(data[0].field_immagine_2_hp_f_2[0].url);
                // text = data[0].field_titolo_1_hp_f_1[0].value;
            })
            .catch(error => console.error(error));

    }, []);

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    return (
        <section id='eventi'>
            <h1 className={`mb-5 text-left font-medium ${lang === 'it' && 'text-205rem'} ${lang === 'de' && 'text-190rem mt-0.5'} ${lang === 'en' && 'text-205rem'} text-white absolute top-0 px-6 py-4 pb-20 z-9999 break-all`}>{title}</h1>
            <img src={image} alt='Info Turismo' className={`w-full ${totem === true && 'h-520'} ${totem === false && 'h-40'} object-cover rounded-20`}/>
            <Backdrop/>
            <Button light link='/eventi'/>
        </section>
    );
}
