import {Button} from "../UI/button";
import {useEffect, useState} from "react";

export const ComuneDiLagundo = () => {
    let lang = localStorage.getItem('i18nextLng');
    const [title, setTitle] = useState(null);
    const [text, setText] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_1_hp_f_3[0].value);
                setText(data[0].field_testo_1_hp_f_3[0].value);
                // text = data[0].field_titolo_1_hp_f_1[0].value;
            })
            .catch(error => console.error(error));

    }, []);
    return (
        <section id='comune-di-lagundo'>
            <h1 className='mb-5 text-left text-4xl font-medium text-black'>{title}</h1>
            <p className='block text-left mb-5 break-word'>{text}</p>

            {lang === 'it' && <Button dark ext={true} link={`https://www.lagundo.eu/it/`}/>}
            {lang === 'de' && <Button dark ext={true} link={`https://www.lagundo.eu/de/`}/>}
            {lang === 'en' && <Button dark ext={true} link={`https://www.lagundo.eu/de/`}/>}
            {/*<Button dark ext={true} link={`https://www.lagundo.eu/${lang}`}/>*/}
        </section>
    );
}
