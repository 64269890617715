import {t} from "i18next";
import {Header} from "../components/Header";
import Iframe from 'react-iframe';
export const ComuneDiLagundoPage = () => {
    let lang = localStorage.getItem('i18nextLng');

    return (
        <>
            <Header/>
        <section id='comune-di-lagundo' className=' main'>
            <h1 className='mb-5 mt-5 text-left text-5xl font-medium text-black'>{t('comunedilagundo')}</h1>
            <div>
                <Iframe url="https://www.lagundo.eu/" width="100%" height="500px" />
            </div>
        </section>
        </>
    );
}
