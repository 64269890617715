import {Button} from "../UI/button";
import {Backdrop} from "../UI/Backdrop";
import {useEffect, useState} from "react";

export const Gastronomia = () => {

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    const [title, setTitle] = useState(null);
    const [text, setText] = useState(null);
    const [image, setImage] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        console.log(`Basic ${auth}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_gastronomia[0].value);
                setText(data[0].field_testo_gastronomia[0].value);
                setImage(data[0].field_immagine_gastronomia[0].url);
            })
            .catch(error => console.error(error));

    }, []);

    return (
        <section id='gastronomia'>
            {totem === true &&
                <>
            <div className='absolute absolute top-0 p-8 pb-20 z-40'>
                <h1 className='mb-5 text-left text-27rem font-medium text-white break-all'>{title}</h1>
                <p className='mb-16 w-full text-white text-left mb-8 text-xl break-word'>{text}
                </p>
            </div>
            <img src={image} alt='Gastronomia' className='w-full h-96 object-cover rounded-20'/>
            <Button light link='/gastronomia' />
            <Backdrop/>

                </>
            }



            {totem === false &&
                <>
                    <div className={`${totem === true && 'absolute p-8'} ${totem === false && 'relative z-50 px-6 py-4'} top-0  pb-20 z-40`}>
                        <h1 className='mb-5 text-left text-3xl font-medium text-white break-all'>{title}</h1>
                        <p className={`mb-16 w-full text-white text-left mb-8 break-word ${totem === true && 'text-xl'}`}>{text}
                        </p>
                    </div>
                    {totem === true &&
                        <Backdrop/>
                    }
                    {totem === false &&
                        <div className={`absolute w-full h-full top-0 p-8 pb-20 z-20`}>
                            <Backdrop/>
                        </div>
                    }
                    <img src={image} alt='Gastronomia' className={`w-full ${totem === true && 'h-520'} ${totem === false && 'absolute top-0 h-full z-10'} object-cover rounded-20`}/>
                    <Button light link='/gastronomia' />
                </>
            }

        </section>
    );
}
