
import logo from '../images/logo.svg'
import logocomunealgund from '../images/comunealgund.svg'
import logoComune from '../images/logocomune.svg'
import logoComunEn from '../images/logocomuneen.svg'
import logoComuneDe from '../images/logocomunede.svg'
import LanguageDropdown from "./UI/LanguageDropdown";
import React, {useEffect, useState} from "react";

export const Header = (props) => {
    let lang = localStorage.getItem('i18nextLng');

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    return(
        <div className='flex items-center flex-wrap w-full justify-between md:pl-24 md:pr-24 pl-6 pr-6 md:h-44 h-24 bg-white border-b-2'>
            <div className='absolute header-content bg-headerGreen px-8 py-8 text-white text-4xl'>
                <div className='fixed top-5 right-5 w-56 h-12 text-right'>
                    <div className='relative inline-block text-left'>
                        {/*{totem === false && <LanguageDropdown/>}*/}
                    </div>
                </div>
            </div>
            <div className='justify-start align-top md:-mt-8 -mt-5'>
                {lang === 'it' && <img src={logo} alt="logo lagundo" width='auto' height='auto' className='md:w-auto w-24'/>}
                {totem === true && lang === 'en' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-24'/>}
                {totem === true && lang === 'de' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-24'/>}
                {totem === false && lang === 'en' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-28'/>}
                {totem === false && lang === 'de' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-28'/>}
                {/*{lang === 'de' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-24'/>}*/}

                {/*<img src={logo} alt="logo lagundo" width='auto' height='auto' className='md:w-auto w-24'/>*/}
            </div>
            <div className='justify-center align-middle'>
                {lang === 'it' && <img src={logoComune} alt="logo comune lagundo" width='auto' height='auto' className='md:w-auto w-40'/>}
                {lang === 'en' && <img src={logoComunEn} alt="logo comune lagundo" width='auto' height='auto' className='md:w-auto w-40'/>}
                {lang === 'de' && <img src={logoComuneDe} alt="logo comune lagundo" width='auto' height='auto' className='md:w-auto w-40'/>}

                {/*<img src={logoComune} alt="logo comune di lagundo" width='auto' height='auto' className='md:w-auto w-40'/>*/}
                {/*<img src={logoComuneDe} alt="logo comune di lagundo" width='auto' height='auto' className='md:w-auto w-40'/>*/}
                {/*<img src={logoComunEn} alt="logo comune di lagundo" width='auto' height='auto' className='md:w-auto w-40'/>*/}
            </div>
            <div className='absolute inline-block text-left bottom-4 md:right-28 right-6 z-9999'>
            {totem === true && <LanguageDropdown/>}
            </div>

        </div>

    )
}


export const HeaderDark = (props) => {
    let lang = localStorage.getItem('i18nextLng');

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    return(
        <div className='flex items-center flex-wrap w-full justify-between md:pl-24 md:pr-24 pl-6 pr-6 md:h-44 h-24 '>
            <div className='absolute header-content bg-transparent px-8 py-8 text-white text-4xl'>
                <div className='fixed top-5 right-5 w-56 h-12 text-right'>
                    <div className='relative inline-block text-left'>
                        {/*{totem === false && <LanguageDropdown/>}*/}
                    </div>
                </div>
            </div>
            <div className='justify-start align-top md:-mt-8 -mt-5'>
                {lang === 'it' && <img src={logo} alt="logo lagundo" width='auto' height='auto' className='md:w-auto w-24'/>}
                {totem === true && lang === 'en' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-24'/>}
                {totem === true && lang === 'de' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-24'/>}
                {totem === false && lang === 'en' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-28'/>}
                {totem === false && lang === 'de' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-28'/>}
                {/*{lang === 'de' && <img src={logocomunealgund} alt="logo lagundo" width='auto' height='auto' className='md:w-52 md:-mt-0.5 w-24'/>}*/}

                {/*<img src={logo} alt="logo lagundo" width='auto' height='auto' className='md:w-auto w-24'/>*/}
            </div>
            <div className='justify-center align-middle'>
                {lang === 'it' && <img src={logoComune} alt="logo comune lagundo" width='auto' height='auto' className='md:w-auto w-40'/>}
                {lang === 'en' && <img src={logoComunEn} alt="logo comune lagundo" width='auto' height='auto' className='md:w-auto w-40'/>}
                {lang === 'de' && <img src={logoComuneDe} alt="logo comune lagundo" width='auto' height='auto' className='md:w-auto w-40'/>}

                {/*<img src={logoComune} alt="logo comune di lagundo" width='auto' height='auto' className='md:w-auto w-40'/>*/}
                {/*<img src={logoComuneDe} alt="logo comune di lagundo" width='auto' height='auto' className='md:w-auto w-40'/>*/}
                {/*<img src={logoComunEn} alt="logo comune di lagundo" width='auto' height='auto' className='md:w-auto w-40'/>*/}
            </div>
            <div className='absolute inline-block text-left bottom-4 md:right-28 right-6 z-9999'>
                {totem === true && <LanguageDropdown/>}
            </div>

        </div>

    )
}
