import React, {Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, HashRouter} from "react-router-dom";

import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';

import './index.css';
import App from './App';
import LoadingSpinner from "./components/UI/LoadingSpinner";
const resources = {
    en: {
        translation: {
            // "Welcome": "Welcome",
            // "Welcome_to": "Welcome to the municipality of lagundo",
            // "inizia_tap": "Start with a Tap!",
            // "language": "Language",
            // "scopridipiu": "Learn more",
            // "data": "Date",
            // "ora": "Time",
            // "meteo": "Weather",
            // "infoturismo": "Tourism Info",
            // "eventi": "Events",
            // "mobilita": "Mobility",
            // "comunedilagundo": "Municipality of lagundo",
            // "albopretorio": "Praetorian register",
            // "alloggi" : "Accommodations",
            // "gastronomia" : "Gastronomy",
            // "attivita" : "Activities",
            // "puntidiinteresse" : "Points of interest",
            // "infoCittadino": "Citizen info",
            // "infoTurismo": "Tourism Info",
            // "serviziButton": "Services",
            // "infoTurismoButton": "Info",
            // "servizioScuola": "School Service",
            // "servizioMensa": "Canteen Service",
            // "servizioScuolaDesc" : "Manage registration, daily attendance and payment of meals through a prepaid payment and SPID.",
            // "comuneDiLagundoDesc": "Access the Municipality’s website to consult all the information and check the related services.",
            // "albopretorioDesc": "Consult documents, notices and news of public interest through the website of Lagundo Municipality.",
            // "alloggiDesc": "Choose and book the accommodation you prefer among hotels, rural houses, B&Bs and other charming locations.",
            // "gastronomiaDesc": "Discover places where you can taste South Tyrolean specialties, vegan dishes, for intolerant, children’s menu and much more. ",
            // "attivitaDesc": "Discover the artistic crafts, historical places and cultural location. Research also for all the services of the territory, pharmacies and shops.",
            // "puntidiinteresseDesc": "Discover the artistic crafts, historical places and cultural location. Research also for all the services of the territory, pharmacies and shops.",
            // "infoTurismoDesc": "Discover the territory of Lagundo and its activities. Find accommodation and places where you can enjoy South Tyrolean hospitality.",
            // "eventiDesc": "Discover all the experiences and events in Lagundo ",
            // "mobilitaDesc": "Consult the routes and timetables in real time of all public transport available.",
            // "anprDesc": "ANPR is the single database that simplifies registry services for all",
            // "anpr": "Demographic services",
            // "logoComune": "../images/logocomuneen.svg"

            "Welcome_to": "Welcome to the local community of Algund",
            "inizia_tap": "Start with a Tap!",
            "infoTurismoButton": "Info",
            "serviziButton": "Services",
            "comunedilagundo": "Local community of Algund",
            "comuneDiLagundoDesc": "Access the local communities website to consult all the information and check the related services.",
            "infoturismo": "Tourism",
            "eventi": "Events",
            "mobilita": "Mobility",
            "scopridipiu": "Learn more",
            "servizioScuola": "School",
            "servizioScuolaDesc" : "Manage the registration, the daily presences and the payment of meals through a prepaid payment and SPID.",
            "anpr": "Demographic services",
            "anprDesc": "ANPR is the institutional database that simplifies the anagraphic registrations services for all.",
            "albopretorio": "Praetorian register",
            "albopretorioDesc": "Consult documents, notices and news of public interest through the website of the local community of Algund.",
            "infoTurismo": "Tourism",
            "infoTurismoDesc": "Discover the territory of Algund and his activities. Find accommodations and places where you can enjoy the South Tyrolean hospitality.",
            "alloggi" : "Accommodations",
            "alloggiDesc": "Choose and book the accommodation you prefer among hotels, rural houses, B&Bs and other charming locations.",
            "gastronomia" : "Gastronomy",
            "gastronomiaDesc": "Discover places where you can taste South Tyrolean specialties, vegan dishes, allergy-friendly dishes, children’s menu’s and much more.",
            "puntidiinteresse" : "Points of interest",
            "puntidiinteresseDesc": "Discover the artistic crafts, historical and cultural places, but also all the services of the territory, pharmacies and shops.",
            "attivita" : "Activities",
            "attivitaDesc": "By foot, by hike or on a horseback: discover all the routes, experiences and activities for young and old, which are offered all year long.",
            "servizioMensa": "School Canteen Service",
            "Welcome": "Welcome",
            "data": "Date",
            "ora": "Time",
            "meteo": "Weather",
            "language": "Language",
            "dimezzainterfaccia": "Half interface",
            "reimpostainterfaccia": "Reset interface",
        }
    },
    it: {
        translation: {
            "Welcome_to": "Benvenuto al Comune di Lagundo",
            "inizia_tap": "Inizia con un Tap!",
            "infoTurismoButton": "Info",
            "serviziButton": "Servizi",
            "comunedilagundo": "Comune di Lagundo",
            "comuneDiLagundoDesc": "Accedi al sito del Comune per consultare tutte le informazioni e utilizzare i servizi connessi.",
            "infoturismo": "Turismo",
            "eventi": "Manifestazioni",
            "mobilita": "Mobilità",
            "scopridipiu": "Scopri di più",
            "servizioScuola": "Scuola",
            "servizioScuolaDesc" : "Gestisci l’iscrizione, le presenze giornaliere e il pagamento dei pasti attraverso un borsellino prepagato e SPID.",
            "anpr": "Servizi demografici",
            "anprDesc": "L'ANPR è la banca dati unica che semplifica i servizi anagrafici per tutti",
            "albopretorio": "Albo pretorio",
            "albopretorioDesc": "Consulta atti, documenti, avvisi e notizie di interesse pubblico attraverso il sito del Comune di Lagundo.",
            "infoTurismo": "Turismo",
            "infoTurismoDesc": "Scopri il territorio di Lagundo e le sue attività. Trova le strutture ricettive e i locali dove poter godere dell’ospitalità altoatesina.",
            "alloggi" : "Alloggi",
            "alloggiDesc": "Scegli e prenota la sistemazione che preferisci tra: hotel, agriturismi, B&B e altre location suggestive.",
            "gastronomia" : "Gastronomia",
            "gastronomiaDesc": "Scopri tutti i locali dove gustare le specialità altoatesine, piatti vegani, piatti per persone che hanno intolleranze, menu bimbi e molto altro.",
            "puntidiinteresse" : "Punti di interesse",
            "puntidiinteresseDesc": "Scopri l’artigianato artistico e luoghi della storia e della cultura, ma anche tutti i servizi del territorio, farmacie e negozi.",
            "attivita" : "Attività",
            "attivitaDesc": "A cavallo, in bici o a piedi: scopri tutti i percorsi, esperienze e attività per grandi e piccini, da fare tutto l’anno.",
            "servizioMensa": "Servizio mensa",
            "Welcome": "Benvenuto",
            "data": "Data",
            "ora": "Ora",
            "meteo": "Meteo",
            "language": "Lingua",
            "dimezzainterfaccia": "Dimezza interfaccia",
            "reimpostainterfaccia": "Reimposta interfaccia",
            // "infoCittadino": "Info Cittadino",
            // "eventiDesc": "Scopri tutte le esperienze e gli eventi da vivere a Lagundo e dintorni ",
            // "mobilitaDesc": "Consulta i percorsi e gli orari in tempo reale di tutti i mezzi pubblici di trasporto disponibili.",
            // "logoComune": "../images/logocomune.svg"

        }
    },
    de: {
        translation: {
            // "Welcome": "Herzlich willkommen",
            // "Welcome_to": "Willkommen in der Gemeinde Lagundo",
            // "inizia_tap": "Beginnen Sie mit einem Tap!",
            // "language": "Sprache",
            // "scopridipiu": "Weitere Informationen",
            // "data": "Datum",
            // "ora": "Zeit",
            // "meteo": "Wetter",
            // "infoturismo": "Info Tourismus",
            // "eventi": "Events",
            // "mobilita": "Mobilität",
            // "comunedilagundo": "Gemeinde Algund",
            // "albopretorio": "Gerichtstafel",
            // "alloggi" : "Unterkünfte",
            // "gastronomia" : "Gastronomie",
            // "attivita" : "Aktivitäten",
            // "puntidiinteresse" : "Sehenswürdigkeiten",
            // "infoCittadino": "Bürger-Infos",
            // "infoTurismo": "Info Tourismus",
            // "serviziButton": "Bürgerservice",
            // "infoTurismoButton": "Info",
            // "servizioScuola": "Schulische Dienstleistungen",
            // "servizioScuolaDesc" : "Verwalten Sie die Anmeldung, die tägliche Anwesenheit und die Bezahlung der Mahlzeiten über eine vorausbezahlte Geldbörse und SPID.",
            // "comuneDiLagundoDesc": "Über die Website der Gemeinde können Sie alle Informationen abrufen und die entsprechenden Dienste nutzen.",
            // "albopretorioDesc": "Auf der Website der Gemeinde Lagundo können Sie Rechtsakte, Dokumente, Bekanntmachungen und Nachrichten von öffentlichem Interesse einsehen.",
            // "alloggiDesc": "Wählen und buchen Sie die Unterkunft, die Sie bevorzugen, zwischen Hotels, Bauernhöfen, B&Bs und anderen malerischen Orten.",
            // "gastronomiaDesc": "Entdecken Sie alle Lokale, in denen Sie Südtiroler Spezialitäten, vegane Gerichte, Gerichte für Intolerante , Kindermenüs und vieles mehr genießen können. ",
            // "attivitaDesc": "Ob zu Pferd, mit dem Fahrrad oder zu Fuß, entdecken Sie alle Routen, Erlebnisse und Aktivitäten für Jung und Alt, die das ganze Jahr über angeboten werden.",
            // "puntidiinteresseDesc": "Entdecken Sie das Kunsthandwerk und die Orte der Geschichte und Kultur, aber auch alle Dienstleistungen der Region, Apotheken und Geschäfte.",
            // "infoTurismoDesc": "Entdecken Sie die Umgebung von Algund und seine Aktivitäten. Finden Sie Unterkünfte und Lokale, wo Sie die Südtiroler Gastfreundschaft genießen können.",
            // "eventiDesc": "Entdecken Sie alle Erlebnisse und Events in Algund und Umgebung",
            // "mobilitaDesc": "Sehen Sie sich die Routen und Fahrpläne aller verfügbaren öffentlichen Verkehrsmittel in Echtzeit an.",
            // "anprDesc": "ANPR ist die einzige Datenbank, die die Registraturdienste für alle",
            // "anpr": "Demografische Dienste",
            // "servizioMensa": "Kantinendienst",
            // "logoComune": "../images/logocomunede.svg"
            "Welcome_to": "Willkommen in der Gemeinde Algund",
            "inizia_tap": "Beginnen Sie mit einem Fingertipp!",
            "infoTurismoButton": "Info",
            "serviziButton": "Bürgerservice",
            "comunedilagundo": "Gemeinde Algund",
            "comuneDiLagundoDesc": "Über die Website der Gemeinde können Sie alle Informationen abrufen und die entsprechenden Dienste nutzen.",
            "infoturismo": "Tourismus",
            "eventi": "Veranstaltungen",
            "mobilita": "Mobilität",
            "scopridipiu": "Weitere Informationen",
            "servizioScuola": "Schule",
            "servizioScuolaDesc" : "Verwalten Sie die Anmeldung, die täglichen Anwesenheiten und die Bezahlung der Mahlzeiten über eine Prepaid-Börse und SPID.",
            "anpr": "Demografische Dienste",
            "anprDesc": "ANPR ist die einheitliche Datenbank, welche die meldeamtlichen Dienste für alle erleichtert.",
            "albopretorio": "Amtstafel",
            "albopretorioDesc": "Auf der Website der Gemeinde Algund können Sie Akte, Dokumente, Bekanntmachungen und Neuigkeiten von öffentlichem Interesse einsehen.",
            "infoTurismo": "Tourismus",
            "infoTurismoDesc": "Entdecken Sie die Umgebung von Algund und seine Freizeitangebote. Finden Sie Unterkünfte und Lokale, wo Sie die Südtiroler Gastfreundschaft erleben können.",
            "alloggi" : "Unterkünfte",
            "alloggiDesc": "Wählen und buchen Sie die Unterkunft, die Sie bevorzugen: zwischen Hotels, Urlaub auf dem Bauernhof, B&Bs und anderen empfehlenswerten Locations.",
            "gastronomia" : "Gastronomie",
            "gastronomiaDesc": "Entdecken Sie alle Lokale, in denen Sie Südtiroler Spezialitäten, vegane Gerichte, Gerichte für Personen mit Unverträglichkeiten, Kindermenüs und vieles mehr genießen können.",
            "puntidiinteresse" : "Sehenswürdigkeiten",
            "puntidiinteresseDesc": "Entdecken Sie das Kunsthandwerk und Orte der Geschichte und Kultur, aber auch alle Dienstleistungen der Umgebung, Apotheken und Geschäfte.",
            "attivita" : "Freizeitaktivitäten",
            "attivitaDesc": "Ob zu Pferd, mit dem Fahrrad oder zu Fuß: entdecken Sie alle Routen, Erlebnisse und Aktivitäten für Jung und Alt, die das ganze Jahr über angeboten werden.",
            "servizioMensa": "Schulausspeisungsdienst",
            "Welcome": "Herzlich willkommen",
            "data": "Datum",
            "ora": "Zeit",
            "meteo": "Wetter",
            "language": "Sprache",
            "dimezzainterfaccia": "Halbe Bildschirmgröße",
            "reimpostainterfaccia": "Bildschirmgröße zurücksetzen",


        }
    }
};

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
        // the translations
        // (tip move them in a JSON file and import them,
        // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
        supportedLngs: ['en', 'it', 'de'],
        fallbackLng: "it",
        detection: {
            order: ['path', 'cookie', 'localStorage', 'htmlTag', 'path', 'subdomain'],
            caches:['cookie', 'localStorage'],
        },
        // backend: {
            resources
            // loadPath: '/assets/locales/{{lng}}/translation.json'
        // }
    });
const loadingMarkup = (
    <div>
        <LoadingSpinner/>
    </div>
)

let totem = true;
    if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
        totem = true;
    } else{
        totem = false;
    }

    if(totem === true){
        const root = ReactDOM.createRoot(document.getElementById('root'));

        root.render(
            <Suspense fallback={loadingMarkup}>
                <HashRouter>
                    <App/>
                </HashRouter>
            </Suspense>
        );
    } else if(totem === false){
        const renderReactDom = () => {
            const root = ReactDOM.createRoot(document.getElementById('root'));

            root.render(
                <Suspense fallback={loadingMarkup}>
                    <HashRouter>
                        <App/>
                    </HashRouter>
                </Suspense>
            );
        };

        if (window.cordova) {
            document.addEventListener('deviceready', () => {
                renderReactDom();
            }, false);
        } else {
            renderReactDom();
        }

    }
// const renderReactDom = () => {
//     const root = ReactDOM.createRoot(document.getElementById('root'));
//
//     root.render(
//         <Suspense fallback={loadingMarkup}>
//             <HashRouter>
//                 <App/>
//             </HashRouter>
//         </Suspense>
//     );
// };
//
// if (window.cordova) {
//     document.addEventListener('deviceready', () => {
//         renderReactDom();
//     }, false);
// } else {
//     renderReactDom();
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
