import React, {useEffect, useState} from "react";
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";

import './App.css';
import './fonts/fonts.css';
import {EventiPage} from "./pages/EventiPage";
import {InfoTurismoPage} from "./pages/InfoTurismoPage";
import {MobilitaPage} from "./pages/MobilitaPage";
import {ComuneDiLagundoPage} from "./pages/ComuneDiLagundoPage";
import {AlloggiPage} from "./pages/AlloggiPage";
import {GastronomiaPage} from "./pages/GastronomiaPage";
import {PuntiDiInteressePage} from "./pages/PuntiDiInteressePage";
import {AttivitaPage} from "./pages/AttivitaPage";
import HomeScreen from "./pages/HomeScreen";
import {MeteoPage} from "./pages/MeteoPage";
import Cookies from "js-cookie";
import {ScuolaPage} from "./pages/ScuolaPage";
import background from './images/background.jpg';
import backgroundEn from './images/backgroundEn.jpg';
import backgroundDe from './images/backgroundDe.jpg';
import {useExternalScripts} from "./components/hooks/useExternalScripts";
import Screensaver from "./pages/Screensaver";
import HalfScreenButton from "./components/UI/HalfScreenButton";
// const ScreenSaver = ({ onClick }) => {
//     let lang = localStorage.getItem('i18nextLng');
//
//     useExternalScripts('https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')
//     return(
//         <div className='overflow-hidden relative z-99999 h-screen' onClick={onClick}>
//             {/*<HeaderDark/>*/}
//             {/*<div className="flex flex-col items-center justify-center h-screen absolute top-0 left-0 z-9999 w-full h-full">*/}
//             {/*    <h1 className="text-5xl text-white">Clicca lo schermo <br/>*/}
//             {/*        per utilizzare <br/>*/}
//             {/*        l'infopoint interattivo*/}
//             <div className='  items-center justify-center z-99999 bottom-64 w-full absolute' >
//                 <div className='w-96 m-auto'>
//                     <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_j0yxcwm6.json"
//                                    loop
//                                    autoplay>
//                     </lottie-player>
//                 </div>
//             </div>
//             {/*    </h1>*/}
//             {/*</div>*/}
//             {lang === 'it' &&
//
//                 <img src={background} className='absolute top-0 left-0 z-9999'></img>
//             }
//             {lang === 'en' &&
//                 <img src={backgroundEn} className='absolute top-0 left-0 z-9999'></img>
//             }
//             {lang === 'de' &&
//                 <img src={backgroundDe} className='absolute top-0 left-0 z-9999'></img>
//             }
//         </div>
//     )
//
// }

function App() {

    const [homeScreen, setHomeScreen] = useState(false)

    const [showScreenSaver, setShowScreenSaver] = useState(false);

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    // const [showScreensaver, setShowScreensaver] = useState(false);
    //
    // useEffect(() => {
    //     let timeoutId;
    //
    //     function resetTimeout() {
    //         if (showScreensaver) {
    //             clearTimeout(timeoutId);
    //             timeoutId = setTimeout(() => setShowScreensaver(false), 500);
    //         }
    //     }
    //
    //     function handleMouseMove() {
    //         resetTimeout();
    //     }
    //
    //     function handleClick() {
    //         setShowScreensaver(false);
    //         resetTimeout();
    //     }
    //
    //     document.addEventListener('mousemove', handleMouseMove);
    //     document.addEventListener('click', handleClick);
    //
    //     resetTimeout();
    //
    //     return () => {
    //         document.removeEventListener('mousemove', handleMouseMove);
    //         document.removeEventListener('click', handleClick);
    //         clearTimeout(timeoutId);
    //     };
    // }, [showScreensaver]);
    //
    // useEffect(() => {
    //     const timeoutId = setTimeout(() => setShowScreensaver(true), 5000);
    //
    //     return () => {
    //         clearTimeout(timeoutId);
    //     };
    // }, []);

    //
    // useEffect(() => {
    //     let timer = null;
    //     const startTimer = () => {
    //         timer = setTimeout(() => {
    //             setShowScreenSaver(true);
    //             console.log('start timer')
    //             console.log(timer)
    //         }, 5000);
    //     };
    //     const stopTimer = () => {
    //         clearTimeout(timer);
    //         console.log('stop-timer')
    //         console.log(timer)
    //     };
    //
    //     startTimer();
    //     window.addEventListener("mousemove", stopTimer);
    //     window.addEventListener("keypress", stopTimer);
    //     window.addEventListener("touchstart", stopTimer);
    //     return () => {
    //         window.removeEventListener("mousemove", stopTimer);
    //         window.removeEventListener("keypress", stopTimer);
    //         window.removeEventListener("touchstart", stopTimer);
    //         clearTimeout(timer);
    //         startTimer();
    //
    //     };
    // }, []);

    // const handleScreenSaverClick = () => {
    //     console.log('ScreensaverClick')
    //     setShowScreenSaver(false);
    //
    // };

    const homeScreenClickHandler = () => {
        setHomeScreen(true)
        Cookies.set('homeScreen', true)
    }
    const [isHalfScreen, setIsHalfScreen] = useState(false);

    useEffect(() => {
        // Recupera lo stato di "isHalfScreen" dal localStorage all'avvio dell'applicazione
        const storedIsHalfScreen = localStorage.getItem('isHalfScreen');
        if (storedIsHalfScreen) {
            setIsHalfScreen(JSON.parse(storedIsHalfScreen));
        }
    }, []);

    useEffect(() => {
        // Salva lo stato di "isHalfScreen" nel localStorage ad ogni cambio
        localStorage.setItem('isHalfScreen', JSON.stringify(isHalfScreen));
    }, [isHalfScreen]);

    return (

        <div className={` ${isHalfScreen ? 'half-screen' : ''} App bg-green font-Ubuntu ${totem === true && 'h-screen pb-32'} ${totem === false && 'h-full pb-10'}`}>
            {/*<div className={`App bg-green font-Ubuntu ${totem === true && 'h-screen pb-32'} ${totem === false && 'h-full pb-10'}`}>*/}
            {/*    <HalfScreenButton isHalfScreen={isHalfScreen} setIsHalfScreen={setIsHalfScreen} />*/}
                {totem === true &&
                <Screensaver />
                }

                {/*<Header/>*/}

                <main>
                    <Routes>
                        {/*{totem === false &&*/}
                        {/*    <Route path="/" index element={<HomeScreen />}>*/}
                        {/*    </Route>*/}
                        {/*}*/}
                        {totem === true &&
                            <Route path="/" index element={<HomePage />}>
                            </Route>
                        }
                        {totem === false &&
                        <Route path="/" exact index element={
                            <HomeScreen onClick={
                                homeScreenClickHandler
                            }/>

                        }>
                        </Route>
                        }
                        {/*<Route path="/screensaver" index element={<ScreenSaver />}>*/}
                        {/*</Route>*/}
                        {totem === false &&
                        <Route path="/homepage" exact element={<HomePage />}>
                        </Route>
                        }

                        <Route path="/eventi" exact element={<EventiPage/>}>
                        </Route>
                        <Route path="/#/eventi" exact element={<EventiPage/>}>
                        </Route>
                        <Route path="/info-turismo" exact element={<InfoTurismoPage />}>
                        </Route>
                        <Route path="/mobilita" exact element={<MobilitaPage />}>
                        </Route>
                        <Route path="/comune-di-lagundo" exact element={<ComuneDiLagundoPage/>}>
                        </Route>
                        <Route path="/alloggi" exact element={<AlloggiPage/>}>
                        </Route>
                        <Route path="/gastronomia" exact element={<GastronomiaPage/>}>
                        </Route>
                        <Route path="/punti-di-interesse" exact element={<PuntiDiInteressePage/>}>
                        </Route>
                        <Route path="/attivita" exact element={<AttivitaPage/>}>
                        </Route>
                        <Route path="/meteo" exact element={<MeteoPage/>}>
                        </Route>
                        <Route path="/scuola" exact element={<ScuolaPage/>}>
                        </Route>

                    </Routes>
                </main>
            </div>
    )
}

export default App;
