import {t} from "i18next";
import {InfoTurismo} from "../infoTurismo/InfoTurismo";
import {Eventi} from "../eventi/Eventi";
import {Mobilita} from "./Mobilita";
import {Data} from "./Data";
import {Time} from "./Ora";
import {Meteo} from "./Meteo";
import {ComuneDiLagundo} from "./ComuneDiLagundo";
import {AlboPretorio} from "./AlboPretorio";
import React, {useEffect, useState} from "react";
import {Anpr} from "./Anpr";
import {Scuola} from "./Scuola";
import {Header} from "../Header";
import HomeScreen from "../../pages/HomeScreen";
import Cookies from "js-cookie";
import ExternalRoute from "../../pages/ExternalRoute";
import {ComuneDiLagundoPage} from "../../pages/ComuneDiLagundoPage";



export const Homepage = () => {

    const [totem, setTotem] = useState(false)
    const [isInfoTurismoVisible, setisInfoTurismoVisible] = useState(true);
    const [isInfoCittadinoVisible, setIsInfoCittadinoVisible] = useState(false);


    const [titleInfo, setTitle] = useState(null);
    const [titleServizi, setServizi] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_pulsante_info[0].value);
                setServizi(data[0].field_pulsante_servizi[0].value);
                // text = data[0].field_titolo_1_hp_f_1[0].value;

            })
            .catch(error => console.error(error));

    }, []);


    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    const infoTurismo = () => {
        setisInfoTurismoVisible(true)
        setIsInfoCittadinoVisible(false)
    }

    const infoCittadino = () => {
        setIsInfoCittadinoVisible(true)
        setisInfoTurismoVisible(false)
    }

    const [homeScreen, setHomeScreen] = useState(false)

    const homeScreenClickHandler = () => {
        setHomeScreen(true)


        Cookies.set('homeScreen', true)
        // localStorage.set('homeScreen', true)
    }

    return(
        <>

            <Header/>
        <section id='homepage' className='main'>
            <div className='md:pl-24 md:pr-24 pl-6 pr-6'>
                {totem === true &&
                    <h1 className='text-left text-7xl font-bold text-white pt-16 mb-16'>{t('Welcome')}</h1>
                }
                {totem === false &&
                    <div className='flex flex-wrap mt-10 mb-10'>
                    <button className={`${isInfoTurismoVisible === true && 'bg-darkGreen text-white'} ${isInfoTurismoVisible === false && 'bg-white text-darkGreen'} mr-3 w-1/2 z-50 border-white px-5 py-3 rounded-20 font-medium text-21px font-medium`} onClick={() => infoTurismo() }>
                        {titleInfo}
                    </button>
                    <button className={` ${isInfoCittadinoVisible === true && 'bg-darkGreen text-white'} ${isInfoCittadinoVisible === false && 'bg-white text-darkGreen'} w-1/2 z-50 border-white px-5 py-3 rounded-20 font-medium text-21px font-medium`} onClick={() => infoCittadino()}>
                        {titleServizi}
                    </button>
                    </div>
                }
                <div className='flex flex-wrap lg:mb-10 mb-0'>
                    {totem === true &&
                        <div className='lg:w-1/3 md:w-full lg:mr-2 flex flex-col w-full mb-3 bg-white rounded-20 p-8 relative'>
                            <Data/>
                        </div>
                    }
                    {totem === true &&
                        <div className='lg:w-1/3 md:w-full lg:ml-2 lg:mr-2 flex flex-col w-full mb-3 bg-white rounded-20 p-8 relative'>
                            <Time/>
                        </div>
                    }
                    {totem === true &&
                        <div className='lg:w-1/3 md:w-full lg:ml-2 flex flex-col w-full mb-3 bg-white rounded-20 p-8 relative'>
                            <Meteo/>
                        </div>
                    }

                    {totem === false && isInfoCittadinoVisible === true &&
                        <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-5 bg-white rounded-20 p-8 pb-20 relative'>
                            <Scuola/>
                        </div>
                    }



                    {totem === false && isInfoCittadinoVisible === true &&
                        <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-5 bg-white rounded-20 p-8 pb-20 relative'>
                            <Anpr/>
                        </div>
                    }
                    {isInfoCittadinoVisible === true && totem === false &&
                        <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-5 bg-white rounded-20 p-8 pb-20 relative'>
                            <AlboPretorio/>
                        </div>
                    }
                </div>

                {isInfoTurismoVisible === true && totem === false &&
                    <div className='flex flex-wrap mb-10'>
                        <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-5 bg-white rounded-20 p-8 pb-20 relative'>
                            <ComuneDiLagundo/>
                        </div>
                        <div className='lg:w-1/3 md:w-full lg:mr-2 flex flex-col w-full mb-5 bg-white rounded-20 relative'>
                            <InfoTurismo/>
                        </div>
                        <div className='lg:w-1/3 md:w-full lg:ml-2 lg:mr-2 flex flex-col w-full mb-5 bg-white rounded-20 relative'>
                            <Eventi/>
                        </div>
                        <div className='lg:w-1/3 md:w-full lg:ml-2 flex flex-col w-full mb-5 bg-white rounded-20 relative'>
                            <Mobilita/>
                        </div>
                    </div>
                }
                {totem === true &&
                    <div className='flex flex-wrap mb-10'>
                        <div className='lg:w-1/3 md:w-full lg:mr-2 flex flex-col w-full mb-3 bg-white rounded-20 relative'>
                            <InfoTurismo/>
                        </div>
                        <div className='lg:w-1/3 md:w-full lg:ml-2 lg:mr-2 flex flex-col w-full mb-3 bg-white rounded-20 relative'>
                            <Eventi/>
                        </div>
                        <div className='lg:w-1/3 md:w-full lg:ml-2 flex flex-col w-full mb-3 bg-white rounded-20 relative'>
                            <Mobilita/>
                        </div>
                    </div>
                }




                {totem === true &&
                    <div className='flex flex-wrap mb-10'>
                            <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-3 bg-white rounded-20 p-8 pb-20 relative'>
                                <ComuneDiLagundo/>
                            </div>
                            <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-3 bg-white rounded-20 p-8 pb-20 relative'>
                                <AlboPretorio/>
                            </div>
                    </div>
                }
            </div>
        </section>
        </>
    )
}
