import {useExternalScripts} from "../components/hooks/useExternalScripts";
import LoadingSpinner from "../components/UI/LoadingSpinner";
import {BackButton} from "../components/UI/BackButton";
import {useEffect, useState} from "react";
import {t} from "i18next";
import {Header} from "../components/Header";

export const EventiPage = () => {


    let lang = localStorage.getItem('i18nextLng');

    // useExternalScripts(`https://widget.lts.it/eventfinder/lts/widget/?widgetid=totem-bbs-algund&language=${lang}&publishers=522822B151CA11D18F1400A02427D15E&eventsactive=True&highlightsdots=True&highlightsactive=false&data-posid=04325B87154C403C982E72282E9ED894&maincolor=96A42C`);
        useExternalScripts(`https://widget.lts.it/eventfinder/lts/widget/?widgetid=totem-bbs-algund&language=${lang}&maincolor=96A42C&highlightsactive=false`);
    // <div id="lts-eventfinder" style="max-width:980px;"> <img src="img/ajax-loader.gif" /> loading ... </div>

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    return (
        <>
            <Header/>
        <section id='eventi' className='bg-white main'>
            <>
                {totem === true &&
                    <>
                        <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 pb-10 pt-10 mb-10 bg-green text-left text-6xl font-medium text-white'>{t('eventi')}</h1>
                        <div className='md:pl-24 md:pr-24 pl-6 pr-6 overflow-auto h-screen-modify-widget'>

                            <iframe className={'w-full h-full pb-16'} src={`https://eventi.lagundo.bbsitalia.com/index${lang}.html`}><LoadingSpinner/></iframe>
                        </div>
                        <BackButton light url='/' />
                    </>
                }
                {totem === false &&
                    <>
                        <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 lg:pb-10 lg:pt-10 pt-8 pb-8 lg:mb-10 bg-green text-left lg:text-6xl text-4xl font-medium text-white'>{t('eventi')}</h1>
                        <div className='md:pl-24 md:pr-24 pl-6 pr-6 overflow-auto h-screen-modify-2'>

                            {/*<div id="lts-eventfinder" className='w-full w-full pb-10'> <LoadingSpinner/> </div>*/}
                            <iframe className={'w-full h-full pb-16'} src={`https://eventi.lagundo.bbsitalia.com/index${lang}.html`}><LoadingSpinner/></iframe>
                        </div>
                        <div className='h-11'>
                            <BackButton light url='/homepage' />
                        </div>
                        {/*h-11*/}
                    </>
                }
            </>

        </section>
        </>

    );
}
