import i18next from "i18next";
import 'flag-icon-css/css/flag-icons.min.css'
import {NavLink} from "react-router-dom";
import Cookies from "js-cookie";
import {useState} from "react";

// const reloadPage = () => {
//     window.location.href = '/homepage'
// }

const languages = [
    {
        code: 'de',
        name: 'Deutsch',
        country_code: 'de'
    },
    {
        code: 'it',
        name: 'Italiano',
        country_code: 'it'
    },
    {
        code: 'en',
        name: 'English',
        country_code: 'gb'
    }
]


const LanguageBox = () => {
    const [homeScreen, setHomeScreen] = useState(false)
    const homeScreenClickHandler = () => {
        setHomeScreen(true)
        Cookies.set('homeScreen', true)
            // window.location.href = '/homepage'
    }
        return (
                        <div className='px-1 py-1 z-99999 w-full pl-8 pr-8'>
                            <div className='text-left'>

                            { languages.map(({code, name, country_code, i}) => (
                                !homeScreen &&
                                <NavLink
                                    key={i}
                                    to={`/homepage`}
                                    className='block text-2xl mb-5 w-full bg-white text-black rounded-20 font-medium bg-opacity-100 px-4 py-2 text-left font-medium text-white hover:bg-opacity-75 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
                                    onClick={() => {
                                        i18next.changeLanguage(code);
                                        homeScreenClickHandler()
                                    }
                                }
                                >
                                            <span
                                                key={i}
                                                className={`flag-icon flag-icon-${country_code} mr-5 mt-1`}
                                            >

                                            </span>
                                            {name}
                                        </NavLink>
                            ))}
                            </div>

                        </div>
        )


}

export default LanguageBox
