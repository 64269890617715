import {BackButton} from "../components/UI/BackButton";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {Header} from "../components/Header";

export const MobilitaPage = () => {
    const [totem, setTotem] = useState(false)
    let lang = localStorage.getItem('i18nextLng');

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    return (
        <>
            <Header></Header>
        <section id='mobilita' className=' main'>
            <>
            {totem === true &&
                <>
            <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 pb-10 pt-10 mb-10 bg-green text-left text-6xl font-medium text-white'>{t('mobilita')}</h1>
            <iframe className='w-full h-screen h-screen-modify' title='Mobilità' src={`https://www.suedtirolmobil.info/${lang}/`}/>
            <BackButton light url='/' />
                </>
            }


            {totem === false &&
                <>
            <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 lg:pb-10 lg:pt-10 pt-8 pb-8 lg:mb-10 bg-green text-left lg:text-6xl text-4xl font-medium text-white'>{t('mobilita')}</h1>
            <iframe className='w-full h-screen-modify-mobile' title='Mobilità' src={`https://www.suedtirolmobil.info/${lang}/`}/>
            <BackButton light url='/homepage' />
                </>
            }
            </>
        </section>
        </>
    );
}
