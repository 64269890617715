import {useEffect, useState} from "react";
import {Button} from "../UI/button";

export const Meteo = () => {
    const [icon, setIcon] = useState('');
    const lat = 46.68343753798777;
    const lon = 11.121284440502306;
    const api = '53b02aad92e3a4c9cfb309f8a0af954a';
    const units = 'metric';


    const lang = localStorage.getItem('i18nextLng');
    const [title, setTitle] = useState(null);

    useEffect(() => {
        fetch(`https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${api}&lang=${lang}&units=${units}`)
            .then(response => response.json())
            .then(data => {
                setIcon(data.weather[0].icon);
            })
    }, [lang]);
    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_3_hp_f_1[0].value);
            })
            .catch(error => console.error(error));

    }, []);

    return (
        <section id='meteo'>
            <h1 className='mb-5 text-left text-5xl font-medium text-black'>{title}</h1>
            <div className='flex flex-wrap'>
                <div className='flex flex-wrap'>
                    {/*<p className='pl-24 pr-24 text-left text-1xl font-bold text-black pt-10'>{city}</p>*/}
                    {/*<p className='pl-24 pr-24 text-left text-1xl font-bold text-black pt-10'>{temp}°C</p>*/}
                    {/*<p className='pl-24 pr-24 text-left text-1xl font-bold text-black pt-10'>{desc}</p>*/}
                    {/*<p className='pl-24 pr-24 text-left text-1xl font-bold text-black pt-10'>{pressure}</p>*/}
                    {/*<p className='pl-24 pr-24 text-left text-1xl font-bold text-black pt-10'>{humidity}</p>*/}
                    {/*<p className='pl-24 pr-24 text-left text-1xl font-bold text-black pt-10'>{wind}</p>*/}
                </div>
                <div className='flex flex-wrap'>
                    <img src={`https://openweathermap.org/img/wn/${icon}@4x.png`} alt='weather icon'/>
                    <Button dark link='/meteo' />
                </div>
            </div>
        </section>
    );
}
