import {Link} from "react-router-dom";
import {t} from "i18next";
import {useEffect, useState} from "react";

export const Button = ({text, link, ext, light, dark}) => {
    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };
    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])



    return(
        <>
            {totem === true && ext === true && dark && <a onClick={scrollToTop}  target={'_blank'}  className='z-99999 bg-green border-white text-white px-8 py-3 rounded-20 font-medium absolute right-8 bottom-5 text-lg font-medium' href={link} rel='noreferrer'>
                {text ? text : t('scopridipiu')}
            </a>}
            {totem === true && ext === true && light && <a onClick={scrollToTop}  target={'_blank'}  className='z-99999 bg-white border-white text-green px-8 py-3 rounded-20 font-medium absolute right-8 bottom-5 text-lg font-medium' href={link} rel='noreferrer'>
                {text ? text : t('scopridipiu')}
            </a>}
            {totem === true && !ext && light && <Link onClick={scrollToTop} to={link} className='z-99999 bg-white border-white text-green px-8 py-3 rounded-20 font-medium absolute right-4 bottom-5 text-lg font-medium'>
                {text ? text : t('scopridipiu')}
            </Link>}
            {totem === true && !ext && dark && <Link onClick={scrollToTop} to={link} className='z-99999 bg-green border-white text-white px-8 py-3 rounded-20 font-medium relative text-lg font-medium'>
                {text ? text : t('scopridipiu')}
            </Link>}

            {totem === false && ext === true && dark && <a onClick={scrollToTop} className='z-99999 bg-green border-white text-white px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium' target='_blank' href={link} rel='noreferrer'>
                {text ? text : t('scopridipiu')}
            </a>}
            {totem === false && ext === true && light && <a onClick={scrollToTop} className='z-99999 bg-white border-white text-green px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium' target='_blank' href={link} rel='noreferrer'>
                {text ? text : t('scopridipiu')}
            </a>}
            {totem === false && !ext && light && <Link onClick={scrollToTop} to={link} className='z-99999 bg-white border-white text-green px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium'>
                {text ? text : t('scopridipiu')}
            </Link>}
            {totem === false && !ext && dark && <Link onClick={scrollToTop} to={link} className='z-99999 bg-green border-white text-white px-6 py-2 rounded-20 font-medium relative text-lg font-medium'>
                {text ? text : t('scopridipiu')}
            </Link>}
        </>
    )
}
