import {BackButton} from "../components/UI/BackButton";
import React, {useEffect, useState} from "react";
import {Mensa} from "../components/homepage/Mensa";
import {Header} from "../components/Header";

export const ScuolaPage = () => {

    const [totem, setTotem] = useState(false)
    const [title, setTitle] = useState(null);
    const [text, setText] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_scuola_extra[0].value);
                setText(data[0].field_testo_scuola_extra[0].value);
            })
            .catch(error => console.error(error));

    }, []);

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    return (
        <>
            <Header></Header>
        <section id='infoTurismo' className=' main'>
            <div className='md:pl-24 md:pr-24 pl-6 pr-6'>
                <h1 className='lg:mb-12 lg:mt-16 mb-6 mt-8 text-left lg:text-7xl text-4xl font-bold text-white'>{title}</h1>
                <p className='mb-16 w-full text-white text-left mb-8 lg:text-2xl text-lg'>{text}</p>
                <div className='flex flex-wrap mb-10'>
                    <div className='lg:w-1/2 md:w-full lg:mr-4 flex flex-col w-full mb-5 bg-white rounded-20 p-8 pb-20 relative'>
                        <Mensa/>
                    </div>

                </div>
                {totem === true &&
                    <BackButton light url='/' />
                }
                {totem === false &&
                    <div className='md:pl-24 md:pr-24 pl-6 pr-6 overflow-auto h-screen-modify-2 fixed z-9999'>
                        <BackButton light url='/homepage' />
                    </div>
                }

            </div>
        </section>
        </>
    );
}
