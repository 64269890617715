import './HomeScreen.css'

import {NavLink} from "react-router-dom";
import React, {useEffect, useState} from "react";
import LanguageBox from "../components/UI/LanguageBox";
import Cookies from "js-cookie";


const HomeScreen = (props) => {

    const [homeScreen, setHomeScreen] = useState(false)
    const [title, setTitle] = useState(null);
    const [title2, setTitle2] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_testo_benvenuto_app[0].value);
                setTitle2(data[0].field_testo_inizia_con_un_tap[0].value);
            })
            .catch(error => console.error(error));

    }, []);
    const homeScreenClickHandler = () => {
        setHomeScreen(true)
        Cookies.set('homeScreen', true)
    }

    return(
        <div>
            {!homeScreen &&
            <NavLink
                to={`/homepage`}
            >

                <div onClick={homeScreenClickHandler} className='fixed top-0 left-0 w-full h-full z-99999 bg-green'>
                    <h1 className=' pl-8 pr-8 text-left text-5xl font-bold text-white pt-14 mb-10 mr-5'>{title}</h1>
                    {/*<p className='text-white pl-16 pr-16 mt-4 text-left leading-5'>Descrizione</p>*/}
                    {/*<p className='text-greenText pl-16 pr-16 mt-4 text-left leading-5'>{t("descrizione_benvenuto")}</p>*/}
                    {/*<img src={homeImage} className='absolute bottom-0 left-0 w-full'/>*/}
                    <LanguageBox/>

                    <div className='centratoHome relative bottom-0 content-center text-center w-full pl-8 pr-8'>



                        <div className='content-center text-center w-full'>

                            {/*<div className='relative text-center flex flex-wrap justify-center items-center mb-5'>*/}
                            {/*    <svg xmlns="http://www.w3.org/2000/svg" width="151" height="151" viewBox="0 0 151 151">*/}
                            {/*        <g id="Raggruppa_800" data-name="Raggruppa 800" transform="translate(-120 -551)">*/}
                            {/*            <circle id="Ellisse_322" data-name="Ellisse 322" cx="75.5" cy="75.5" r="75.5" transform="translate(120 551)" fill="#fff" opacity="0.3"/>*/}
                            {/*            <circle id="Ellisse_321" data-name="Ellisse 321" cx="55.5" cy="55.5" r="55.5" transform="translate(140 571)" fill="#fff" opacity="0.8"/>*/}
                            {/*        </g>*/}
                            {/*    </svg>*/}
                            {/*    <img src={homeImageGif} alt='immagine di blocco' className='absolute w-36 h-36'/>*/}
                            {/*/!**!/*/}
                            {/*</div>*/}
                            {/*<p className='text-white font-bold text-xl'>inizia_tap</p>*/}
                            <p className='text-white font-bold text-3xl mb-20'>{title2}</p>
                            {/*<button className={`bg-darkGreen text-white mr-3 mb-16 w-full z-50 border-white px-6 py-6 rounded-20 font-medium text-3xl font-medium`} >*/}
                            {/*    Inizia*/}
                            {/*</button>*/}
                        </div>

                    </div>

                </div>

            </NavLink>}

        </div>
    )
}

export default HomeScreen
