import {Button} from "../UI/button";
import {t} from "i18next";

export const Anpr = () => {
    return (
        <section id='mensa'>
            <h1 className='mb-5 text-left text-4xl font-medium text-black'>{t('anpr')}</h1>
            <p className='block text-left mb-5 break-word'>{t('anprDesc')}</p>

            <Button dark ext={true} link='https://www.anagrafenazionale.interno.it/servizi-al-cittadino/' />
        </section>

    );
}
