import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import backLightImage from '../../images/back.svg';
import backDarkImage from '../../images/back-dark.svg';

export const BackButton = ({url, light, dark}) => {

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    return (
        <>
        {totem === true &&
            <div className='bg-green w-full h-32 fixed bottom-0 left-0 flex flex-wrap items-center'>
                    <Link to={url} className='z-9999 text-2xl text-green hover:text-green-dark relative bottom-0 left-24'>
                        {light && <img src={backLightImage} alt='back'/>}
                        {dark && <img src={backDarkImage} alt='back'/>}
                    </Link>
            </div>
        }
            {totem === false &&
                <div className='bg-green w-full h-24 fixed bottom-0 left-0 flex flex-wrap items-center'>
                        <Link to={url} className='z-9999 text-2xl text-green hover:text-green-dark relative bottom-0 left-6'>
                            {light && <img src={backLightImage} className='w-20' alt='back'/>}
                            {dark && <img src={backDarkImage} className='w-20' alt='back'/>}
                        </Link>
                </div>
            }

        </>

    );
}
