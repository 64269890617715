import React, { useState, useEffect, useRef, useCallback } from 'react';
import {useExternalScripts} from "../components/hooks/useExternalScripts";
import background from "../images/background.jpg";
import backgroundEn from "../images/backgroundEn.jpg";
import backgroundDe from "../images/backgroundDe.jpg";

function Screensaver() {
    const [showScreensaver, setShowScreensaver] = useState(false);
    const timeoutRef = useRef();

    const resetTimeout = useCallback(() => {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => setShowScreensaver(true), 600000);
    }, []);

    useEffect(() => {
        let lastInteractionTime = Date.now();

        function handleInteraction() {
            if (showScreensaver) {
                lastInteractionTime = Date.now();
                setShowScreensaver(false);
            } else {
                lastInteractionTime = Date.now();
                resetTimeout();
            }
        }

        // document.addEventListener('mousemove', handleInteraction);
        document.addEventListener('click', handleInteraction);
        document.addEventListener('touchmove', handleInteraction);
        document.addEventListener('touchstart', handleInteraction);

        resetTimeout();

        return () => {
            // document.removeEventListener('mousemove', handleInteraction);
            document.removeEventListener('click', handleInteraction);
            document.removeEventListener('touchmove', handleInteraction);
            document.removeEventListener('touchstart', handleInteraction);
            clearTimeout(timeoutRef.current);
        };
    }, [resetTimeout, showScreensaver]);

    function handleScreensaverClick() {
        setShowScreensaver(false);
    }

    useEffect(() => {
        if (showScreensaver) {
            clearTimeout(timeoutRef.current);
        }
    }, [showScreensaver]);

    let lang = localStorage.getItem('i18nextLng');

    useExternalScripts('https://unpkg.com/@lottiefiles/lottie-player@latest/dist/lottie-player.js')


    return (
        <>
            {showScreensaver && (
                <div className='overflow-hidden relative z-99999 h-screen' onClick={handleScreensaverClick} onTouchStart={handleScreensaverClick}>
                    {/*<HeaderDark/>*/}
                    {/*<div className="flex flex-col items-center justify-center h-screen absolute top-0 left-0 z-9999 w-full h-full">*/}
                    {/*    <h1 className="text-5xl text-white">Clicca lo schermo <br/>*/}
                    {/*        per utilizzare <br/>*/}
                    {/*        l'infopoint interattivo*/}
                    <div className='  items-center justify-center z-99999 bottom-64 w-full absolute' >
                        <div className='w-96 m-auto'>
                            <lottie-player src="https://assets5.lottiefiles.com/packages/lf20_j0yxcwm6.json"
                                           loop
                                           autoplay>

                            </lottie-player>
                        </div>
                    </div>
                        {/*    </h1>*/}
                    {/*</div>*/}
                    {lang === 'it' &&
                        <img src={background} className='absolute top-0 left-0 z-9999'></img>
                    }
                    {lang === 'en' &&
                        <img src={backgroundEn} className='absolute top-0 left-0 z-9999'></img>
                    }
                    {lang === 'de' &&
                        <img src={backgroundDe} className='absolute top-0 left-0 z-9999'></img>
                    }
                </div>
            )}
        </>
    );
}

export default Screensaver;
