import {BackButton} from "../components/UI/BackButton";
import React, {useEffect, useState} from "react";
import {t} from "i18next";
import {Header} from "../components/Header";

export const MeteoPage = () => {
    const [totem, setTotem] = useState(false)
    let lang = localStorage.getItem('i18nextLng');

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    return (
        <>
        <Header></Header>
        <section id='meteo' className=' main'>
            <h1 className='md:pl-24 md:pr-24 pl-6 pr-6 pb-10 pt-10 mb-10 bg-green text-left text-6xl font-medium text-white'>{t('meteo')}</h1>
            <div className='h-full'>

                <iframe src={`https://www.algund.info/${lang}/lagundo-e-dintorni/lagundo-live/meteo/17-0.html`} className='w-full h-screen-modify' title='Meteo'></iframe>
                {totem === true &&
                    <BackButton light url='/'/>
                }
                {totem === false &&
                    <BackButton light url='/homepage'/>
                }
            </div>
        </section>
        </>

    );
}
