import data from '../../images/data.svg';
import { useEffect, useState } from "react";

export const Data = () => {
    const lang = localStorage.getItem('i18nextLng');

    const [title, setTitle] = useState(null);
    const [currentDate, setCurrentDate] = useState(new Date());

    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';

        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_1_hp_f_1[0].value);
            })
            .catch(error => console.error(error));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentDate(new Date());
            console.log("Data aggiornata:", new Date());
        }, 60 * 1000); // Aggiorna ogni minuto
        return () => clearInterval(interval);
    }, []);

    const month = currentDate.toLocaleString(`${lang}-${lang.toUpperCase()}`, { month: 'long' });
    const day = currentDate.getDate();
    const year = currentDate.getFullYear();

    return (
        <section id='data'>
            <h1 className='mb-5 text-left text-5xl font-medium text-black'>{title}</h1>
            <div className='flex flex-wrap justify-center items-center text-center relative w-full h-64'>
                <img src={data} alt='data' className='relative w-56 h-64' />
                <div className='data-container absolute'>
                    <span className='month block w-full text-white text-7xl font-bold mb-2'>{day}</span>
                    <span className='day block w-full text-white font-medium text-3xl capitalize'>{month}</span>
                    <span className='year block w-full text-white font-medium text-3xl'>{year}</span>
                </div>
            </div>
        </section>
    );
};
