import ora from '../../images/ora.svg'
import {useEffect, useState} from "react";

export const Time = () => {

    const [time, setTime] = useState(new Date());

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    const [title, setTitle] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_2_hp_f_1[0].value);
            })
            .catch(error => console.error(error));

    }, []);

    return (
        <section id='data'>
            <h1 className='mb-5 text-left text-5xl font-medium text-black'>{title}</h1>
            <div className='flex flex-wrap justify-center items-center text-center relative'>
                <img src={ora} alt='data' className='w-40 h-40'/>
                <div className='w-full bg-green mt-4 rounded-20 text-7xl text-white font-bold'>
                    {time.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}
                </div>
            </div>
        </section>
    );
}
