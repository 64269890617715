import {Alloggi} from "../components/infoTurismo/Alloggi";
import {Gastronomia} from "../components/infoTurismo/Gastronomia";
import {Attivita} from "../components/infoTurismo/Attivita";
import {PuntiDiInteresse} from "../components/infoTurismo/PuntiDiInteresse";
import {BackButton} from "../components/UI/BackButton";
import {t} from "i18next";
import React, {useEffect, useState} from "react";
import {Header} from "../components/Header";

export const InfoTurismoPage = () => {

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])
    // const [title, setTitle] = useState(null);
    const [text, setText] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        console.log(`Basic ${auth}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setText(data[0].field_testo_turismo_ext_1[0].value);
            })
            .catch(error => console.error(error));

    }, []);
    return (
        <>
        <Header></Header>
        <section id='infoTurismo' className=' main'>
            <div className='md:pl-24 md:pr-24 pl-6 pr-6'>
                <h1 className='lg:mb-12 lg:mt-16 mb-6 mt-8 text-left lg:text-7xl text-4xl font-bold text-white'>{t('infoturismo')}</h1>
                <p className='mb-16 w-full text-white text-left mb-8 lg:text-2xl text-lg'>{text}</p>
                <div className='flex flex-wrap mb-10'>
                    <div className={`lg:w-1/2 md:w-full flex flex-col w-full bg-white rounded-20 relative mb-10 ${totem === true && 'mr-4'}`}>
                        <Alloggi/>
                    </div>
                    <div className={`lg:w-1/2 md:w-full flex flex-col w-full bg-white rounded-20 relative mb-10 ${totem === true && 'mr-4'}`}>
                        <Gastronomia/>
                    </div>
                    <div className='flex flex-col w-full bg-white rounded-20 relative mb-10'>
                        <PuntiDiInteresse/>
                    </div>
                    <div className='flex flex-col w-full bg-white rounded-20 relative mb-10'>
                        <Attivita/>
                    </div>

                </div>
                {totem === true &&
                <BackButton light url='/' />
                }
                {totem === false &&
                <div className='md:pl-24 md:pr-24 pl-6 pr-6 overflow-auto h-screen-modify-2 fixed z-9999'>
                        <BackButton light url='/homepage' />
                </div>
                }

            </div>
        </section>
        </>

    );
}
