import {t} from "i18next";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";

export const Scuola = () => {


    const [title, setTitle] = useState(null);
    const [text, setText] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_scuola[0].value);
                setText(data[0].field_testo_scuola[0].value);
            })
            .catch(error => console.error(error));

    }, []);


    return (
        <section id='scuola'>
            <h1 className='mb-5 text-left text-4xl font-medium text-black'>{title}</h1>
            <p className='block text-left mb-5 break-word'>{text}</p>

            {/*<Button dark ext={true} link='https://myportal.multiutilitycard.it/portaleGenitori/login/login' />*/}
            <Link className='z-9999 bg-green border-white text-white px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium' to={'/scuola'} rel='noreferrer'>
                {t('scopridipiu')}
            </Link>
            {/*<Button dark link='/scuola' />*/}
        </section>

    );
}
