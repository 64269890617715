import {Backdrop} from "../UI/Backdrop";
import {t} from "i18next";
import {useEffect, useState} from "react";

export const Mobilita = () => {
    let lang = localStorage.getItem('i18nextLng');

    const [totem, setTotem] = useState(false)

    useEffect(() => {
        if(process.env.REACT_APP_LAGUNDO_TOTEM === 'true'){
            setTotem(true);
        } else{
            setTotem(false);
        }
    }, [])

    const [title, setTitle] = useState(null);
    const [image, setImage] = useState(null);


    useEffect(() => {
        const url = `https://backend.lagundo.bbsitalia.com/${localStorage.getItem('i18nextLng')}/api/testi`;
        // const urlToken = 'https://backend.lagundo.bbsitalia.com/session/token'
        const username = 'bbsuser';
        const password = 'Bettolino@3';


        const auth = btoa(`${username}:${password}`);
        const options = {
            method: 'GET',
            headers: {
                                'Authorization': `Basic ${auth}`,
                'Content-Type': 'application/json',
                'Accept': 'application/json'

            }
        };
        fetch(url, options)
            .then(response => response.json())
            .then(data => {
                setTitle(data[0].field_titolo_3_hp_f_2[0].value);
                setImage(data[0].field_immagine_3_hp_f_2[0].url);
            })
            .catch(error => console.error(error));

    }, []);
    return (
        <section id='mobilita'>
            {/*<h1 className='mb-5 text-left text-205rem font-medium text-white absolute top-0 px-6 py-4 pb-20  z-9999'>{title}</h1>*/}
            <h1 className='mb-5 text-left text-205rem font-medium text-white absolute top-0 px-6 py-4 z-9999'>{title}</h1>
            <img src={image} alt='Info Turismo' className={`w-full ${totem === true && 'h-520'} ${totem === false && 'h-40'} object-cover rounded-20`}/>

            {lang === 'it' &&

                <a target={'_blank'} className='z-9999 bg-white border-white text-green px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium' href={'https://www.suedtirolmobil.info/it'} rel='noreferrer'>
                    {t('scopridipiu')}
                </a>
            }
                {/*<Button light ext={true} link={`https://www.suedtirolmobil.info/it`}/>}*/}
            {lang === 'en' &&
                <a target={'_blank'} className='z-9999 bg-white border-white text-green px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium' href={'https://www.suedtirolmobil.info/en'} rel='noreferrer'>
                    {t('scopridipiu')}
                </a>
            }
            {lang === 'de' &&
                <a target={'_blank'} className='z-9999 bg-white border-white text-green px-6 py-2 rounded-20 font-medium absolute left-6 bottom-5 text-lg font-medium' href={'https://www.suedtirolmobil.info/de'} rel='noreferrer'>
                    {t('scopridipiu')}
                </a>
            }
            {/*<Button light link='/mobilita'/>*/}
            <Backdrop/>

        </section>
    );
}
