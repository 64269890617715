export const useExternalScripts = (url) => {
    // setTimeout(() => {

    const script = document.createElement("script");
    script.src = url;
    script.async = true;
    // const scriptExist = document.querySelector(`script[src="${url}"]`);
    // if (!scriptExist) {
        document.body.appendChild(script);
    // }
    return () => {
        document.body.removeChild(script);
    };
    // },1000);

}
