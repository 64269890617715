import React from "react";
import {Homepage} from "../components/homepage/Homepage";

const HomePage = () => {

    return (
        <section>

            <Homepage/>
        </section>
    );
}

export default HomePage;
